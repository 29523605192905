<template>
  <v-container class="pt-9">
    <div class="row align-items-center">
      <div class="col"></div>
      <div class="col">
        <div class="header">
          <router-link to="/site/home"
            ><img
              src="@/assets/logo-deitada.png"
              alt="APPDATURMA"
              class="logo"
              style="width: 280px"
          /></router-link>
        </div>
      </div>
      <div class="col">
        <div class="pa-2 ma-1 float-end">
          <v-btn
            block
            color="secondary"
            class="ma-0"
            @click="$router.push('/aluno/login')"
            >Entrar</v-btn
          >
        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="12" md="6" class="text-center text-md-left" order="2">
        <div class="pa-6" :class="{ cadastro: $vuetify.breakpoint.mdAndUp }">
          <div
            class="
              font-weight-medium
              text-center text-md-left text-h4 text-md-h3
              pb-4
            "
          >
            Cadastro
          </div>
          <p class="subtitle-1 paragraph--text text-center text-md-left">
            Comece agora mesmo a organizar a formatura dos seus sonhos!
            Cadastre-se, é grátis!
          </p>
          <v-form v-model="valid" class="pt-2">
            <v-text-field
              label="Nome"
              v-model="nome"
              :rules="nomeRules"
              required
            ></v-text-field>
            <v-text-field
              v-model="telefone"
              label="Telefone"
              id="id2"
              required
            ></v-text-field>
            <v-text-field
              @keydown.space.prevent
              v-model.trim="email"
              label="Email"
              id="id3"
              :rules="emailRules"
              required
            ></v-text-field>
            <v-row class="align-center" no-gutters>
              <v-checkbox
                v-model="termosDeUso"
                id="checkbox"
                label="Concordo com os "
              >
              </v-checkbox>
              <a
                target="_blank"
                href="https://s3.us-east-2.amazonaws.com/br.com.trulogic.bucket/appdaturma/politica_de_privacidade.pdf"
                class="ml-1 checkbox-termo text-decoration-none"
              >
                Termos e Condições de Uso
              </a>
            </v-row>
            <v-btn
              class="br-10 text-none px-13"
              :loading="loadingBtnLock == true"
              color="primary"
              @click="cadastrar()"
              :disabled="!valid"
            >
              Enviar
            </v-btn>
          </v-form>
        </div>
      </v-col>
      <v-col cols="12" sm="6" order="1" class="text-center d-none d-md-block">
        <img src="@/assets/site/cadastro-simples.svg" alt="" />
      </v-col>
    </v-row>
    <vModel :mailto="email" :showme="modal" @closeme="handleClose()"></vModel>
  </v-container>
</template>

<script>
import userCadastro from "@/middleware/cadastros.js";
import vModel from "./modal.vue";
export default {
  props: ["id"],
  components: {
    vModel
  },
  data() {
    return {
      valid: false,
      modal: false,
      termosDeUso: false,
      email: null,
      nome: null,
      telefone: null,
      codigo: null,
      loadingBtnLock: false,
      nomeRules: [v => !!v || "Por favor insira seu nome e sobrenome"],
      emailRules: [
        v => !!v || "Email is required",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ]
    };
  },
  methods: {
    handleClose() {
      this.modal = false;
      if (this.codigo && this.codigo.length > 3) {
        this.$root.infoModal(
          "Agora basta aguardar o administrador da turma/evento ativá-lo "
        );
      }
    },
    handleError(msg) {
      this.$store.commit("site/setModal", {
        tipo: "erro",
        mensagem: msg,
        toggle: true
      });
    },
    cadastrar() {
      let self = this;
      if (this.loadingBtnLock) return false;
      this.email = this.email.toLowerCase();
      if (this.email == null || this.nome == null || this.telefone == null) {
        this.handleError(
          "Algo deu errado no formulário, verifique se o email, nome, telefone estão corretos"
        );
        return false;
      }
      if (!this.termosDeUso) {
        this.handleError(
          "É necessário que concorde com os termos para ingressar."
        );
        return false;
      }
      if (!this.valid) return false;

      this.loadingBtnLock = true;
      if (this.codigo.length < 3) this.codigo = "";
      userCadastro
        .cadastro_simples(this.email, this.nome, this.telefone, this.codigo)
        .then(response => {
          if (
            response.data ==
            "Solicitação enviada com sucesso. Aguarde o administrador da turma/evento ativá-lo"
          ) {
            this.$root.sucessoModal(response.data);
          } else {
            self.modal = true;
          }
        })
        .finally(() => {
          self.loadingBtnLock = false;
        })
        .catch(error => {
          this.$root.errorModal(error.data);
          //window.console.error(error);
        });
    }
  },
  computed: {
    getID() {
      return this.id;
    }
  },
  mounted() {
    this.codigo = this.id == null ? "" : this.id;
  }
};
</script>

<style lang="scss" scoped>
.titulo {
  font-size: 36px;
  font-family: $fontSiteFamily;
  font-weight: $fontSiteMedium;
}
.lowSize {
  font-size: 12px;
  vertical-align: center;
  text-align: left;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  padding: 5px;
  input {
    margin: 5px;
  }
  div {
    margin: 5px;
    a {
      color: #6f7cb2;
      font-weight: 500;
      text-decoration: none;
    }
  }
}
.space {
  padding: 35px;
}
.form {
  //max-width: auto;
  margin: 20px auto;
  padding: 10px;
  //max-width: 400px;
}
.descricao {
  color: #6f7cb2 !important;
  font-size: 16px;
}
.imagem {
  margin: 0 auto;
  //width: 540px;
  height: 540px;
}
.reduceWidth {
  img {
    width: 200px;
    height: auto;
  }
}
.pa-2 {
  width: 45%;
}
</style>
