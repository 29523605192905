<template>
  <v-card class="pa-4 ma-0 my-4">
    <div class="btnOpenEdit" @click="$emit('openEditor')">
      <v-avatar size="40" style="cursor: pointer" color="primary">
        <v-icon size="20" dark>mdi-pencil-outline</v-icon>
      </v-avatar>
    </div>
    <v-row justify="center" align="center" class="text-center">
      <v-col cols="12" sm="6">
        <div class="font-weight-bold text-subtitle-1 d-none d-sm-block">
          Item
        </div>
        <div class="text-sm-subtitle-2  text-subtitle-1">
          <div class="d-none d-sm-block primary--text">{{ item.nome }}</div>
          <div class="d-block d-sm-none secondary--text">{{ item.nome }}</div>
        </div>
        <div class="d-sm-none d-block pa-3">
          <v-divider></v-divider>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="font-weight-bold">Quantidade</div>
        <div class="text-subtitle-2 primary--text">
          {{ item.qtdItem }}
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="text-center">
      <v-col cols="12" sm="6">
        <div class="font-weight-bold">Valor Total</div>
        <div class="text-subtitle-2 primary--text">
          {{ item.valor | currency }}
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="font-weight-bold">Tipo de Calculo</div>
        <div class="text-subtitle-2 primary--text">
          <div v-if="item.calcularPorFormando == true">
            <v-icon color="primary">mdi-account-multiple</v-icon>
            Por participantes
          </div>
          <div v-else>
            <v-icon color="primary" size="20">mdi-currency-usd</v-icon>
            Valor fixo
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.btnOpenEdit {
  position: absolute;
  top: -10px;
  right: 10px;
}
</style>
