<template>
  <div v-if="true">
    <v-container
      :class="{ 'px-0': $vuetify.breakpoint.mdAndDown }"
      class="pr-0 mr-0"
    >
      <div id="hero">
        <v-row>
          <v-col
            sm="12"
            md="6"
            class="d-flex align-center hero-space order-2 order-md-1"
            :class="{ 'hero-no-space': $vuetify.breakpoint.mdAndDown }"
          >
            <div
              class="px-8"
              :class="{ 'pb-15': $vuetify.breakpoint.mdAndDown }"
            >
              <div
                class="text-h4 text-md-h3 font-weight-bold text-center text-md-left py-5 secondary--text"
              >
                Arrecadar e gerenciar os valores do seu evento nunca foi tão
                fácil
              </div>
              <p
                class="mb-1 text-md-h6 third--text font-weight-regular text-center text-md-left"
              >
                Com o <b>Appdaturma</b> você tem liberdade e autonomia para
                arrecadar individualmente os valores e destiná-los da maneira
                que quiser. E o principal, você mesmo faz os pagamentos
                DIRETAMENTE os seus fornecedores, sem intermediários.
              </p>
              <p
                class="mb-md-12 mb-9 text-md-h6 third--text font-weight-regular text-center text-md-left"
              >
                Cadastre-se agora mesmo e faça um teste totalmente gratuito para
                sentir como é prático e simples organizar o seu evento com o
                <b>Appdaturma</b>.
              </p>
              <div>
                <a href="#calculadora" class="text-decoration-none">
                  <v-btn
                    :class="{
                      'v-btn--block mb-4': $vuetify.breakpoint.mdAndDown
                    }"
                    class="br-10 px-md-13 mr-md-8 mr-4 white--text rounded-lg subtitle-1 font-weight-medium text-none"
                    color="primary"
                  >
                    Planejar
                  </v-btn>
                </a>
                <router-link class="text-decoration-none" to="/site/cadastro">
                  <v-btn
                    color="secondary"
                    outlined
                    :class="{ 'v-btn--block': $vuetify.breakpoint.mdAndDown }"
                    class="br-10 px-md-13 block rounded-lg subtitle-1 font-weight-medium text-none"
                  >
                    Cadastrar
                  </v-btn>
                </router-link>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="order-1 order-md-2">
            <div
              :style="
                'background-image: url(' +
                  require('@/assets/site/landingPage/hero1-back.svg') +
                  ')'
              "
              style="background-size: cover; background-origin: left top;"
            >
              <v-img
                src="@/assets/site/landingPage/hero1.svg"
                max-width="755"
                class="d-none d-md-block"
              />
              <v-img
                src="@/assets/site/landingPage/hero1.svg"
                max-width="auto"
                class="d-block d-md-none"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <!-- SESSÃO REMOVIDA ATÉ O VÍDEO SER FEITO -->
    <v-container class="pa-0 py-12" fluid v-if="false">
      <v-row>
        <v-col cols="12" md="6" class="pa-md-10 pa-5 order-2 order-md-1 ">
          <div
            class="pa-5 pa-md-0 py-5 py-md-4 text-h4 text-md-h3 font-weight-bold text-center text-md-left secondary--text"
          >
            Autonomia, liberdade e segurança na palma da sua mão!
          </div>
          <div
            class="font-weight-bold text-center text-md-left text-subtitle-1 text-md-h6 secondary--text py-2"
          >
            Por que escolher o Appdaturma?
          </div>
          <div
            class="pa-5 pa-md-0 py-5 py-md-2 text-md-h6 text-subtitle-2 text-md-left text-center third--text"
          >
            Nada melhor do que a autonomia e a segurança de você mesmo gerenciar
            o dinheiro do seu evento. No Appdaturma todo os valores do seu
            evento ficam depositados na sua conta e só sairão de lá para pagar o
            fornecedor que e quando você mandar. Não corra riscos de que outros
            façam festa com o seu dinheiro, venha para o Appdaturma.
            <br />
            <div class="mt-5"></div>
            Cadastre seu evento ou sua turma, e faça agora mesmo um teste
            totalmente gratuito para sentir como é fácil e seguro.
          </div>
          <div class="mt-2 text-center">
            <v-btn
              color="primary"
              class="subtitle-1 font-weight-medium text-none"
            >
              Gostei, quero uma demonstração!
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="0"
          md="6"
          style="background-color:#F3F5FF"
          class="order-1 order-md-2 centerAll"
        >
          <div
            style="display:grid; height: 450px; grid-template-columns: 1fr; grid-template-rows: 1fr auto 1fr;"
          >
            <div></div>
            <div>
              <v-icon size="65">
                mdi-play-outline
              </v-icon>
            </div>
            <div></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- FIM SESSÃO REMOVIDA ATÉ O VÍDEO SER FEITO -->
    <v-container class="py-15">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" class="order-1 order-md-1 text-left">
          <v-img
            class="d-none d-md-block"
            src="@/assets/site/landingPage/hero2.svg"
            max-width="475"
          ></v-img>
          <center class="d-block d-md-none">
            <v-img
              src="@/assets/site/landingPage/hero2.svg"
              max-width="300"
            ></v-img>
          </center>
        </v-col>
        <v-col cols="12" md="6" class="pa-2 order-2 order-md-2 ">
          <div
            class="text-h4 text-md-h3 font-weight-bold text-center text-md-left secondary--text"
          >
            Prático, Eficiente e Seguro!
          </div>
          <div
            class="py-8 font-weight-bold text-center text-md-left text-subtitle-1 text-md-h6 secondary--text"
          >
            Gerencie os recursos da sua festa em um só lugar de forma
            transparente e segura.
          </div>
          <p
            class="mb-1 text-md-h6 text-subtitle-2 third--text font-weight-regular text-center text-md-left"
          >
            O <b>Appdaturma</b> é um sistema que simplifica o gerenciamento
            financeiro do seu evento de maneira muito rápida, moderna e segura.
          </p>
          <p
            class="text-md-h6 text-subtitle-2 third--text font-weight-regular text-center text-md-left"
          >
            Aqui você faz as arrecadações e os pagamentos de forma que a gestão
            financeira ser mais barata, segura e transparente.
          </p>
          <router-link class="text-decoration-none" to="/site/cadastro">
            <v-btn
              color="primary"
              class="mt-7 px-14 rounded-lg subtitle-1 font-weight-medium text-none"
            >
              Gostei, quero uma demonstração!
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="py-12">
      <div
        id="funcionalidades"
        class="pa-5 pa-md-0 py-5 py-md-4 text-h4 text-md-h3 font-weight-bold text-center secondary--text"
      >
        Funcionalidades
      </div>
      <v-container
        fluid
        class="d-none d-md-block pa-0 my-12 mt-5 px-10"
        style="margin-top: 80px"
      >
        <v-row justify="center" aling="center">
          <v-col cols="4">
            <div
              style="display: grid; grid-template-columns: 1fr; grid-template-rows: 1fr 1fr; height: 100%; vertical-align:center; align-content: center; align-self: center; align-items: center"
            >
              <div>
                <v-row justify="center" align="center">
                  <v-col cols="8" class="text-right">
                    <div class="font-weight-bold text-h6 secondary--text pb-5">
                      Arrecadação de Fundos
                    </div>
                    <div
                      class="text-body-2 third--text text-right"
                      style="font-weight: 400; line-height: 26px;"
                    >
                      Seu sonho, sua festa, suas regras. Cadastre o evento e
                      arrecade dinheiro individualmente de forma segura e
                      digital. Acompanhe os gastos e acesse informações de forma
                      transparente e segura.
                    </div>
                  </v-col>
                  <v-col cols="4" style="text-align: end">
                    <v-avatar size="97" color="primary">
                      <v-icon size="50" dark>
                        mdi-gold
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row justify="center" align="center">
                  <v-col cols="8" class="text-right">
                    <div class="font-weight-bold text-h6 secondary--text pb-5">
                      Pagamento de Fornecedores Direto
                    </div>
                    <div
                      class="text-body-2 third--text text-right"
                      style="font-weight: 400;line-height: 26px;"
                    >
                      Aqui é assim, você paga os fornecedores diretamente, sem
                      intermediários. Evite atrasos e esquecimentos, organize,
                      agende e faça os pagamentos dos fornecedores de dentro da
                      plataforma.
                    </div>
                  </v-col>
                  <v-col cols="4" style="text-align: end">
                    <v-avatar size="97" color="primary">
                      <v-icon size="50" dark>
                        mdi-account-cash
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col
            align-self="center"
            cols="4"
            class="text-center justify-center"
          >
            <!-- PHONE -->
            <center>
              <v-img
                src="@/assets/site/landingPage/smartphone1.svg"
                height="590"
                max-width="250"
                contain
              ></v-img>
            </center>
          </v-col>
          <v-col cols="4">
            <!-- RIGHT -->
            <div
              style="display: grid;grid-template-columns: 1fr; grid-template-rows: 1fr 1fr; height: 100%; vertical-align:center; align-content: center; align-self: center; align-items: center"
            >
              <div>
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-avatar size="97" color="primary">
                      <v-icon size="50" dark>
                        mdi-notebook-multiple
                      </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <div
                      class="font-weight-bold text-h6 text-left secondary--text pb-5"
                    >
                      Calculadora de Planejamento
                    </div>
                    <div
                      class="text-body-2 third--text text-left"
                      style="font-weight: 400;line-height: 26px;"
                    >
                      Planejamento é o primeiro passo para a realização da sua
                      formatura perfeita. Veja como é fácil criar um plano de
                      arrecadação inteligente com a exclusiva Calculadora de
                      Planejamento do App da Turma.
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-avatar size="97" color="primary">
                      <v-icon size="50" dark>mdi-currency-usd-circle</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <div
                      class="font-weight-bold text-h6 text-left secondary--text pb-5"
                    >
                      Descontos Exclusivos
                    </div>
                    <div
                      class="text-body-2 third--text text-left"
                      style="font-weight: 400;line-height: 26px"
                    >
                      Clientes Appdaturma contam com um portifólio exclusivo de
                      fornecedores que farão toda a diferença no seu evento.
                      Conte com nossos parceiros!
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- mob -->
      <v-container fluid class="d-block d-md-none pa-0">
        <v-row class="pa-5 px-8" justify="center" align="center">
          <v-col cols="12" sm="8" class="order-2 order-sm-1">
            <div class="text-h6 secondary--text text-center text-sm-right">
              Arrecadação de Fundos
            </div>
            <div
              class="text-body-2 third--text text-center text-sm-right"
              style="font-weight: 400; line-height: 26px;"
            >
              Seu sonho, sua festa, suas regras. Cadastre o evento e arrecade
              dinheiro individualmente de forma segura e digital.
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="text-center order-1 order-sm-2">
            <v-avatar size="97" color="primary">
              <v-icon size="50" dark>
                mdi-gold
              </v-icon>
            </v-avatar>
          </v-col>
        </v-row>
        <v-row class="pa-5 px-8" justify="center" align="center">
          <v-col cols="12" sm="4" class="text-center ">
            <v-avatar size="97" color="primary">
              <v-icon size="50" dark>
                mdi-account-cash
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="12" sm="8" class="">
            <div class="text-h6 secondary--text text-center text-sm-left">
              Pagamento de Fornecedores Direto
            </div>
            <div
              class="text-body-2 third--text text-center text-sm-left"
              style="font-weight: 400; line-height: 26px;"
            >
              Aqui é assim, você paga os fornecedores diretamente, sem
              intermediários. Evite atrasos e esquecimentos, organize, agende e
              faça os pagamentos dos fornecedores de dentro da plataforma.
            </div>
          </v-col>
        </v-row>
        <v-row class="pa-5 px-8" justify="center" align="center">
          <v-col cols="12" sm="4" class="text-center ">
            <v-avatar size="97" color="primary">
              <v-icon size="50" dark>
                mdi-notebook-multiple
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="12" sm="8" class="">
            <div class="text-h6 secondary--text text-center text-sm-left">
              Calculadora de Planejamento
            </div>
            <div
              class="text-body-2 third--text text-center text-sm-left"
              style="font-weight: 400; line-height: 26px;"
            >
              Planejamento é o primeiro passo para a realização da sua formatura
              perfeita. Veja como é fácil criar um plano de arrecadação
              inteligente com a exclusiva Calculadora de Planejamento do App da
              Turma.
            </div>
          </v-col>
        </v-row>
        <v-row class="pa-5 px-8" justify="center" align="center">
          <v-col cols="12" sm="8" class="order-2 order-sm-1">
            <div class="text-h6 secondary--text text-center text-sm-right">
              Descontos Exclusivos
            </div>
            <div
              class="text-body-2 third--text text-center text-sm-right"
              style="font-weight: 400; line-height: 26px;"
            >
              Clientes Appdaturma contam com um portifólio exclusivo de
              fornecedores que farão toda a diferença no seu evento. Conte com
              nossos parceiros!
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="text-center order-1 order-sm-2">
            <v-avatar size="97" color="primary">
              <v-icon size="50" dark>mdi-currency-usd-circle</v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container class="pb-0 ml-0 mt-12" id="calculadora">
      <v-row>
        <v-col cols="0" md="6" class="pa-0">
          <div
            :style="
              'background-image: url(' +
                require('@/assets/site/landingPage/fundo-calculadora.svg') +
                ')'
            "
            style="background-size: cover; background-position: top;background-origin: content-box;"
          >
            <v-img
              src="@/assets/site/landingPage/calculadora.svg"
              max-width="540"
            />
          </div>
        </v-col>
        <v-col cols="12" md="6" class="mb-15">
          <v-card elevation="0" class="card-calculator px-md-12 px-12" tile>
            <div
              class="pa-5 pa-md-0 py-5 py-md-4 text-h4 text-md-h3 font-weight-bold text-center text-md-left secondary--text"
            >
              Calculadora de Formaturas
            </div>
            <p
              class="text-md-h6 text-subtitle-2 third--text font-weight-regular text-center text-md-left"
            >
              Veja como é fácil criar um plano de arrecadação inteligente com a
              exclusiva Calculadora de Formaturas do Appdaturma.
            </p>
            <v-form>
              <v-row class="form-calculator">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Número de participantes"
                    type="number"
                    min="0"
                    hint="Número aproximado de participantes do evento"
                    v-model="form.qtdAluno"
                    :readonly="
                      form.tipoCalculo === 'DESCRICAO_ITENS' &&
                        form.itens.length > 0
                    "
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Data do evento"
                    v-model="data"
                    type="date"
                    hint="Data real ou aproximada do evento"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    :items="tiposCalculo"
                    item-text="descricao"
                    item-value="id"
                    label="Calcular usando"
                    @change="changeTipoCalculo"
                  >
                    <template v-slot:append-outer>
                      <v-avatar @click="modalInfoCalculo = true">
                        <v-icon color="blue darken-2">
                          mdi-information
                        </v-icon>
                      </v-avatar>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-if="form.tipoCalculo === 'VALOR_PARCELA'"
                    v-model.lazy="form.valorParcela"
                    v-money="$root.money"
                    prefix="R$"
                    label="Valor da parcela"
                  />

                  <v-text-field
                    v-if="form.tipoCalculo === 'VALOR_TOTAL'"
                    v-model.lazy="form.valorTotal"
                    v-money="$root.money"
                    prefix="R$"
                    label="Valor total"
                  />

                  <v-autocomplete
                    v-if="form.tipoCalculo === 'DESCRICAO_ITENS'"
                    v-model="itemParaLista"
                    :items="itensCalculadora"
                    :search-input.sync="buscaItem"
                    label="Itens"
                    name="Itens"
                    item-value="id"
                    item-text="nome"
                    required
                    return-object
                  >
                    <template v-slot:no-data>
                      <div class="pa-2 px-4">
                        <div>O item não existe, deseja acrescentar ?</div>
                        <div>
                          <v-btn
                            @click="acrescentarItemCustom()"
                            color="primary"
                            outlined
                            class="ma-1"
                            ><v-icon>mdi-plus-box</v-icon>
                            {{ buscaItem }}</v-btn
                          >
                        </div>
                      </div>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon
                        v-if="itemParaLista != null"
                        color="primary"
                        @click="addItemNaLista()"
                        v-text="'mdi-plus-box'"
                      ></v-icon>
                      <v-icon
                        v-else
                        disabled
                        @click="addItemNaLista(itemParaLista)"
                        v-text="'mdi-plus-box'"
                      ></v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="form.tipoCalculo === 'DESCRICAO_ITENS'">
                  <v-expansion-panels
                    multiple
                    accordion
                    :value="expanded"
                    class="hidden-sm-and-down"
                  >
                    <v-expansion-panel :key="0">
                      <v-expansion-panel-header
                        ><div
                          style="font-weight: 500; font-size: 15px; color: secondary"
                        >
                          <v-badge
                            color="primary"
                            :content="form.itens.length"
                            :value="form.itens.length"
                          >
                            Itens selecionados</v-badge
                          >
                        </div></v-expansion-panel-header
                      >
                      <v-expansion-panel-content v-if="form.itens.length > 0">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="4" class="text-subtitle-2 text-left"
                            >Nome</v-col
                          >
                          <v-col cols="4" class="text-subtitle-2 text-center"
                            >Valor</v-col
                          >
                          <v-col cols="2" class="text-subtitle-2 text-center"
                            >Qtd.</v-col
                          >
                          <v-col cols="2" class="text-subtitle-2 text-right"
                            >Ações</v-col
                          >
                        </v-row>
                        <v-row
                          :key="index"
                          v-for="(item, index) in form.itens"
                          class="my-2"
                          justify="center"
                          align="center"
                        >
                          <v-col cols="4" class="text-body-2 text-left">{{
                            item.nome
                          }}</v-col>
                          <v-col cols="4" class="text-body-2 text-center">{{
                            item.valor | currency
                          }}</v-col>
                          <v-col cols="2" class="text-body-2 text-center">{{
                            item.qtdItem
                          }}</v-col>
                          <v-col cols="2" class="text-right">
                            <v-avatar
                              size="45"
                              style="cursor: pointer"
                              color="rgba(200,200,200,0.2)"
                            >
                              <v-icon
                                size="20"
                                class="mx-1"
                                @click="openModalEdit(item)"
                                color="primary"
                                >mdi-pencil</v-icon
                              >
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div
                    class="blocos hidden-md-and-up"
                    v-if="form.itens.length > 0"
                  >
                    <div class="text-h6 secondary--text">
                      Itens na lista
                      <v-avatar
                        size="35"
                        class="primary--text text-subtitle-1"
                        style="background-color: rgba(200,200,200,0.4);"
                      >
                        {{ form.itens.length }}
                      </v-avatar>
                    </div>
                    <vItemListaCalculadora
                      :key="index"
                      v-for="(item, index) in form.itens"
                      :item="item"
                      @openEditor="openModalEdit(item)"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="mt-5 pa-3" align="end" justify="end">
              <v-btn
                :loading="loadingCalculadora"
                @click="requestCalculo()"
                class="botao px-md-13 br-10 white--text rounded-lg subtitle-1 font-weight-medium text-none"
                color="secondary"
                >Calcular</v-btn
              >
            </v-row>
            <v-row>
              <modalResultadoCalculadora
                :receiver="recebido"
                @close="handleClose()"
                :dialog="diag"
              />
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <vItemEditor
      v-if="modalEdicao"
      :item="itemForEdit"
      :quantidadeAluno="form.qtdAluno"
      @close="fecharModalItem()"
      @removeItem="removeItemFromList"
      @saveEdit="salvarEdicao"
    />
    <vModalPWA
      :installPrompt="payloadInstallPrompt"
      v-if="displayModalPWA == true"
      @fecharModalPWA="closeModalPwa"
    />
    <v-dialog max-width="500" v-model="modalInfoCalculo">
      <v-card class="pa-4 white--text" color="info">
        <div class="d-grid grid-col-1-auto centralizaTudo text-left">
          <div class="text-h6 font-weight-bold pl-5">
            Tipos de calculo
          </div>
          <v-btn text class="white--text" @click="modalInfoCalculo = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <ul class="mt-3">
          <li class="pb-3">
            <b>Valor da parcela:</b> se você já tem o valor da parcela que cada
            participante vai pagar
          </li>
          <li class="pb-3">
            <b>Valor total:</b> se você quer calcular o valor da parcela a
            partir de um valor total que quer arrecadar
          </li>
          <li class="pb-3">
            <b>Itens da lista:</b> Neste caso você precisa saber o valor
            aproximado de cada item da lista para calcular automaticamente o
            valor total e o valor da parcela.
          </li>
        </ul>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { TipoService, Cadastros } from "@/middleware/index";
import modalResultadoCalculadora from "./resultadoCalculadora.vue";
import vItemEditor from "./modalEditarItem";
import vModalPWA from "@/components/site/modalPWA.vue";
import { VMoney } from "v-money";
import vItemListaCalculadora from "./itemDaListaCalculadora";

export default {
  components: {
    modalResultadoCalculadora,
    vItemEditor,
    vModalPWA,
    vItemListaCalculadora
  },
  directives: { money: VMoney },
  data() {
    return {
      buscaItem: "",
      modalEdicao: false,
      modalInfoCalculo: false,
      expanded: [0],
      tiposCalculo: [],
      itensCalculadora: [],
      diag: false,
      load: false,
      data: "",
      emailNewsletter: "",
      loadingBtnNewsletter: false,
      loadingCalculadora: false,
      recebido: {},
      itemForEdit: {},
      itemParaLista: null,
      errorHandleToggle: false,
      form: {
        tipoCalculo: "",
        qtdAluno: 5,
        mesFormatura: 0,
        anoFormatura: 0,
        valorTotal: 0,
        valorParcela: 0,
        itens: []
      },
      installBtn: "none",
      displayModalPWA: false,
      payloadInstallPrompt: null,
      valid: false,
      emailRules: [
        v => !!v || "É necessário um email para o envio",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email é inválido"
      ]
    };
  },
  beforeMount() {
    TipoService.getTiposCalculo().then(response => {
      this.tiposCalculo = response.data;
    });
    TipoService.getItensCalculadora().then(response => {
      this.itensCalculadora = response.data;
    });
  },
  getters: {
    GET_LISTA_CALCULO() {
      return this.itensCalculadora;
    }
  },
  created() {
    //TODO: PWA;
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.payloadInstallPrompt = e;
      this.installBtn = "block";
      this.displayModalPWA = true;
    });
  },
  methods: {
    converteValor(valorStr) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (
        valorStr &&
        valorStr !== null &&
        valorStr !== undefined &&
        valorStr !== ""
      ) {
        // return Number(valorStr.replace(',', '.').replace(/[^0-9.-]+/g, ''))
        return Number(
          valorStr
            .replaceAll(".", "")
            .replace(",", ".")
            .replace(/[^0-9.-]+/g, "")
        );
      }
      return 0;
    },
    validarNewsletter() {
      this.$refs.form.validate();
    },
    closeModalPwa() {
      this.displayModalPWA = false;
    },
    triagemData() {
      //diversos
      if (this.form.tipoCalculo == "") {
        this.$root.errorModal("Você não selecionou o tipo de cálculo");
        this.errorHandleToggle = true;
        return false;
      }
      if (this.form.qtdAluno < 1) {
        this.$root.errorModal("É necessário incluir a quantidade de alunos");
        this.errorHandleToggle = true;
        return false;
      }
      //especificos
      if (this.form.tipoCalculo == "DESCRICAO_ITENS") {
        if (this.form.itens.length < 1) {
          this.errorHandleToggle = true;
          this.$root.errorModal(
            "Você não adicionou nenhum item, acrescente um item, para dar início ao cálculo"
          );
          return false;
        }
      }
      if (this.form.tipoCalculo == "VALOR_TOTAL") {
        if (this.form.valorTotal < 1) {
          this.errorHandleToggle = true;
          this.$root.errorModal(
            "Falta adicionar o valor total e não pode ser negativo"
          );
          return false;
        }
      }
      if (this.form.tipoCalculo == "VALOR_PARCELA") {
        if (this.form.valorParcela < 1) {
          this.errorHandleToggle = true;
          this.$root.errorModal(
            "Falta adicionar o valor da parcela e não pode ser negativo"
          );
          return false;
        }
      }
      if (
        isNaN(this.form.anoFormatura) ||
        this.form.anoFormatura == null ||
        this.form.anoFormatura == undefined
      ) {
        this.errorHandleToggle = true;
        this.$root.errorModal("Falta definir a data de formatura");
        return false;
      }
      if (
        isNaN(this.form.mesFormatura) ||
        this.form.mesFormatura == null ||
        this.form.mesFormatura == undefined
      ) {
        this.errorHandleToggle = true;
        this.$root.errorModal("Falta definir a data de formatura");
        return false;
      }
      this.errorHandleToggle = false;
    },
    acrescentarItemCustom() {
      let payload = {
        id: "",
        valor: 1200,
        nome: this.buscaItem,
        imgUrl: null,
        qtdItem: 1,
        calcularPorFormando: null,
        sigla: this.buscaItem
      };
      this.form.itens.push(payload);
      this.$root.infoModal("Item adicionado a lista");
    },
    openModalEdit(item) {
      this.itemForEdit = item;
      this.modalEdicao = true;
    },
    salvarEdicao(item) {
      let ar = this.form.itens.find(it => it.id == item.id);
      let indexQuery = this.form.itens.indexOf(ar);
      if (indexQuery != -1) {
        this.form.itens.splice(indexQuery, 1, item);
      } else {
        //teste
      }
      this.modalEdicao = false;
    },
    removeItemFromList(item) {
      let ar = this.form.itens.find(it => it.id == item.id);
      let indexQuery = this.form.itens.indexOf(ar);
      if (indexQuery != -1) {
        this.form.itens.splice(indexQuery, 1);
      } else {
        //teste
      }
      this.modalEdicao = false;
    },
    fecharModalItem() {
      this.modalEdicao = false;
    },
    addItemNaLista() {
      let Item = this.itemParaLista;
      let quantidadeDoItem = Number(Item.qtdItem);
      let quantidadeDeAlunos = Number(this.form.qtdAluno);
      let valorUnitario = Number(Item.valor);
      if (quantidadeDeAlunos < 1) {
        this.$root.errorModal("Deve ter pelo menos 1 aluno na turma");
        return false;
      }
      if (Item.calcularPorFormando == true) {
        Item.valor = valorUnitario * quantidadeDoItem * quantidadeDeAlunos;
      }
      this.form.itens.push(Item); // adiciona na lista
      let verificaExistencia = this.itensCalculadora.indexOf(Item);
      if (verificaExistencia > -1) {
        this.itensCalculadora.splice(verificaExistencia, 1);
      }
      this.itemParaLista = null;
    },
    handleClose() {
      this.diag = false;
      this.recebido = {};
    },
    requestCalculo() {
      this.loadingCalculadora = true;
      this.form.mesFormatura = Number(this.data[5] + "" + this.data[6]);
      this.form.anoFormatura = Number(
        `${this.data[0]}${this.data[1]}${this.data[2]}${this.data[3]}`
      );
      this.form.qtdAluno = Number(this.form.qtdAluno);
      this.triagemData();
      this.form.valorTotal = this.converteValor(this.form.valorTotal);
      this.form.valorParcela = this.converteValor(this.form.valorParcela);
      if (this.errorHandleToggle == true) {
        this.loadingCalculadora = false;
        return false;
      }
      Cadastros.calcularValor(this.form)
        .then(resp => {
          if (resp.status == 200) {
            this.recebido = resp.data;
            this.diag = true;
            this.loadingCalculadora = false;
          }
        })
        .catch(err => {
          this.$store.commit("global/setModal", {
            mensagem: `Erro: ${err.data}`,
            toggle: true,
            tipo: "erro"
          });
          this.loadingCalculadora = false;
        });
    },
    changeTipoCalculo(tipoCalculo) {
      this.form.tipoCalculo = tipoCalculo;
      this.form.itens = [];
      this.form.valorTotal = 0;
      this.form.valorParcela = 0;
    }
  }
};
</script>
