<template>
  <div>
    <v-dialog v-model="showme" max-width="431">
      <v-card class="diag">
        <div class="top">
          <v-icon @click="close()">
            mdi-close
          </v-icon>
        </div>
        <v-card-title class="headline">
          Email de verificação enviado!
        </v-card-title>
        <v-card-text>
          <p class="desc">
            Esta ação requer um email de verificação.<br />
            Por favor, verifique sua caixa de entrada e siga as instruções.
            Email enviado para:<br /><br />
            {{ mailto }}
          </p>
        </v-card-text>
        <v-card-actions>
          <!--<v-spacer></v-spacer>-->
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["showme", "mailto"],
  methods: {
    close() {
      this.$emit("closeme");
    }
  }
};
</script>

<style scoped>
.top {
  text-align: right;
}
.btn {
  padding: 10px;
  border-radius: 8px;
  color: white;
  background-color: #7275ff;
  width: 160px;
}
.diag {
  padding: 25px;
}
.headline {
  color: #091133;
}
.desc {
  color: #7275ff;
}
</style>
