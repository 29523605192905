<template>
  <div>
    <vModal />
    <div class="site">
      <vContent />
    </div>
  </div>
</template>

<script>
import vModal from "@/components/site/modalCustom.vue";
import vContent from "@/views/site/content/index.vue";

export default {
  components: {
    vModal,
    vContent
  }
};
</script>

<style lang="scss">
.site {
  font-family: $fontSiteFamily;
}
</style>
