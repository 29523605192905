var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay":true,"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-5"},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h5 pb-4 pl-2 secondary--text font-weight-bold"},[_vm._v(" Alterando item ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"formulario pa-3"},[_c('div',{staticClass:"text-subtitle-1 text-center grey--text font-weight-light"},[_vm._v(" Item ")]),_c('div',{staticClass:"text-h6 text-center pb-5"},[_vm._v(" "+_vm._s(_vm.include.nome)+" ")]),_c('v-text-field',{attrs:{"name":"Quantidade","label":"Quantidade","type":"number","outlined":""},model:{value:(_vm.include.qtdItem),callback:function ($$v) {_vm.$set(_vm.include, "qtdItem", _vm._n($$v))},expression:"include.qtdItem"}}),_c('div',[_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
            locale: 'pt-Pt',
            currency: null,
            valueAsInteger: false,
            distractionFree: false,
            precision: 2,
            autoDecimalMode: true,
            valueRange: { min: 0 },
            allowNegative: false
          }),expression:"{\n            locale: 'pt-Pt',\n            currency: null,\n            valueAsInteger: false,\n            distractionFree: false,\n            precision: 2,\n            autoDecimalMode: true,\n            valueRange: { min: 0 },\n            allowNegative: false\n          }"}],attrs:{"name":"ValorUnit","prefix":"R$","label":"Valor Unitário","outlined":""},model:{value:(_vm.valorUnit),callback:function ($$v) {_vm.valorUnit=$$v},expression:"valorUnit"}})],1),_c('div',{staticClass:"text-center"},[_c('v-checkbox',{staticClass:"text-right",attrs:{"label":"Calcular pela quantidade de formandos","value":_vm.include.calcularPorFormando},model:{value:(_vm.include.calcularPorFormando),callback:function ($$v) {_vm.$set(_vm.include, "calcularPorFormando", $$v)},expression:"include.calcularPorFormando"}})],1),_c('div',{staticClass:"text-center py-5"},[_c('v-divider'),_c('div',{staticClass:"grey--text text--darken-1 text-h6 font-weight-light"},[_vm._v(" Valor total do item ")]),_c('div',{staticClass:"total primary--text text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.include.valor))+" ")]),_c('v-divider')],1)],1),(_vm.errorArray.length > 0)?_c('div',{staticClass:"pa-2 red--text"},[_c('div',{staticClass:"mb-2"},[_vm._v(" Erros encontrados ")]),_c('ul',_vm._l((_vm.errorArray),function(item,index){return _c('li',{key:index,staticClass:"black--text pa-1 text-subtitle-2"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"acoes pa-2 my-3"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary"},on:{"click":function($event){return _vm.removeItem()}}},[_vm._v("Excluir")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.salvaItem()}}},[_vm._v("Salvar")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }